var W = Object.defineProperty;
var _ = (o, e, i) => e in o ? W(o, e, { enumerable: !0, configurable: !0, writable: !0, value: i }) : o[e] = i;
var d = (o, e, i) => (_(o, typeof e != "symbol" ? e + "" : e, i), i);
const w = typeof window < "u";
function v() {
  var o = navigator.userAgent.toLowerCase() || navigator.vendor.toLowerCase() || window.opera;
  return /windows phone/i.test(o) ? "windows" : /android/i.test(o) ? "android" : /ipad|iphone|ipod/.test(o) && !window.MSStream ? "ios" : "desktop";
}
const q = v(), C = !!navigator.userAgent.toLowerCase().match(/(ipad)|(iphone)|(ipod)|(android)|(webos)/i), E = navigator.userAgent.toLowerCase().includes("canvas");
class D {
  constructor(e) {
    d(this, "display");
    d(this, "delay");
    d(this, "widget");
    d(this, "backDrop");
    if (!w)
      return;
    e = Object.assign({}, {
      fontFamily: '"Source Sans Pro", "Arial", sans-serif',
      // Font family for widget texts, defaults to system safe fonts
      textColor: "#222",
      // Widget texts color (any color property value)
      textHeading: "Get 10% OFF",
      // Heading Text
      textDescription: "Scan our QR to download the app on your device and unlock the discount",
      // Description text
      widgetColor: "#fff",
      // Widget BG color
      backDrop: !0,
      backDropZIndex: 888888,
      qrOptions: {
        text: "https://redirect.mobiloud.com/?ios=https://itunes.apple.com/&android=https://play.google.com",
        size: 150,
        margin: 0
      },
      position: "bottom-left",
      // Position of the widget, default 'top'. 'top' | 'bottom'
      animation: "fadeIn",
      // Widget animation, default 'fadeIn'. 'fadeIn' | 'scaleUp' | 'slideBottom' | 'slideTop' | 'slideLeft' | 'slideRight' | null,
      display: "onLoad",
      // Display options, default 'onLoad'. 'onLoad' | 'onScrollDown' | 'onScrollUp'
      radius: "10px",
      // Widget radius with units
      delay: 0,
      // defines how much time to wait until the element shows up
      shadow: !0,
      // If true applies soft shadow, true | false
      useSession: !0,
      zindex: 999999,
      maxWidth: "400px",
      sessionExpire: 1440
    }, e);
    let t = { right: "unset", left: "unset", bottom: "unset", top: "unset" };
    switch (e.position) {
      case "bottom-right":
        t.right = "32px", t.bottom = "32px";
        break;
      case "bottom-left":
        t.left = "32px", t.bottom = "32px";
        break;
      case "top-right":
        t.top = "32px", t.right = "32px";
        break;
      case "top-left":
        t.top = "32px", t.left = "32px";
        break;
      case "center":
        t.right = "50%", t.bottom = "50%";
        break;
      default:
        t.right = "50%", t.bottom = "50%";
    }
    const m = `
    
    .ml-qrWidget {
      display:none;
      position: fixed;
      z-index: ${e.zindex};
      background-color: ${e.widgetColor};
      box-shadow: ${e.shadow ? "0 0 4px 1px #00000014" : "none"} ;
      transition: all 0.3ms ease-in-out;
      font-family: ${e.fontFamily};
      animation: ${e.animation + " 0.5s both"};
      font-size: 1rem;
      border-radius: ${e.radius};
      right: ${t.right};
			bottom: ${t.bottom};
			left: ${t.left};
			top: ${t.top};
      color: ${e.textColor};
      max-width: ${e.maxWidth};
      transform: ${e.position === "center" || !e.position ? "translate(50%, 50%) !important" : "unset"}
    }
    .ml-qrWidget__backDrop{
      display: none;
      position: fixed;
      width: 100%;
      height: 100%;
      background-color: #00000073;
      top: 0;
      z-index: ${e.backDropZIndex}
    }

    .ml-qrWidget__qrWrapper {
      min-width: ${e.qrOptions.size}px;
      min-height: ${e.qrOptions.size}px;
      
    }
    .ml-qrWidget__image {
      border-radius: ${e.radius}
    }
    .ml-qrWidget__wrapper {
      display: flex;
      align-items: center;
      padding: 1.8em 1.5em;
      gap: 15px
    }
    
    .ml-qrWidget__description {
      margin: 0
    }

    .ml-qrWidget__title {
      font-weight: bold;
      font-size: 1.3rem;
      margin: 0 0 5px 0
    }

    
    .ml-qrWidget__closebutton {
      position: absolute;
      top: 5px;
      height: unset;
      right: 10px;
      font-size: 30px;
      font-weight: revert;
      cursor: pointer;
      line-height: 30px;
      color: #333;
    }
    
    .toggle--visible{
      display: block;
    }
      
    @keyframes fadeIn {
      0% {
          opacity: 0;
      }
      100% {
          opacity: 1;
       }
  }

  @keyframes scaleUp {
    0% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes slideBottom {
    0% {
      transform: translateY(1000px);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes slideTop {
    0% {
      transform: translateY(-1000px);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes slideLeft {
    0% {
      transform: translateX(-1000px);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideRight {
    0% {
      transform: translateX(1000px);
    }
    100% {
      transform: translateX(0);
    }
  }
      /* CHECK MEDIA CLASSES */
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .qrWidget-toggle {display: none !important}
      }

      @supports (-ms-ime-align:auto), (-ms-accelerator:true) {
        .qrWidget-toggle {display: none !important}
      }
    `, g = (a) => Object.entries(a).map((s) => s.map(encodeURIComponent).join("=")).join("&");
    function u(a) {
      const s = document.createElement("div");
      s.className = "ml-qrWidget";
      const c = document.createElement("div");
      c.className = "ml-qrWidget__backDrop";
      const r = document.createElement("div");
      r.className = "ml-qrWidget__wrapper";
      const h = document.createElement("div");
      h.className = "ml-qrWidget__qrWrapper";
      const f = document.createElement("img");
      f.className = "ml-qrWidget__image", f.src = "https://quickchart.io/qr?" + g(a.qrOptions);
      const p = document.createElement("div");
      p.className = "ml-qrWidget__content";
      const b = document.createElement("h4");
      b.className = "ml-qrWidget__title", b.innerHTML = a.textHeading, p.appendChild(b);
      const x = document.createElement("p");
      x.className = "ml-qrWidget__description", x.innerHTML = a.textDescription, p.appendChild(x);
      const l = document.createElement("span");
      return l.id = "ml-qrWidget__closebutton", l.className = "ml-qrWidget__closebutton", l.textContent = "×", l.addEventListener("click", () => {
        if (s.style.display = "none", c.style.display = "none", a.useSession) {
          const y = (/* @__PURE__ */ new Date()).getTime(), k = new Date(y + a.sessionExpire * 60 * 1e3);
          window.localStorage.setItem("widgetClosed", k.toString());
        }
      }), h.appendChild(f), r.appendChild(h), r.appendChild(p), r.appendChild(l), s.appendChild(r), document.body.appendChild(c), document.body.appendChild(s), { appWidget: s, backDrop: c };
    }
    let n = u(e);
    this.addStyle(m), this.display = e.display, this.delay = e.delay, this.widget = n, this.backDrop = e.backDrop;
  }
  // (1) inserts css in page
  addStyle(e) {
    const i = document.createElement("link");
    i.setAttribute("rel", "stylesheet"), i.setAttribute("type", "text/css"), i.setAttribute("href", "data:text/css;charset=UTF-8," + encodeURIComponent(e)), document.head.appendChild(i);
  }
  init() {
    const e = window.localStorage.getItem("widgetClosed"), i = this.display, t = this.widget, m = this.delay, g = this.backDrop;
    if (e)
      if (console.log("exists"), console.log(e), /* @__PURE__ */ new Date() > new Date(e))
        localStorage.removeItem("widgetClosed");
      else
        return;
    if (!w)
      return;
    g && (t == null || t.backDrop.classList.add("toggle--visible"));
    const u = () => {
      let n = 0;
      switch (i) {
        case "onLoad":
          t == null || t.appWidget.classList.add("toggle--visible");
          break;
        case "onScrollDown":
          window.addEventListener("scroll", function() {
            let a = window.scrollY || document.documentElement.scrollTop;
            a < n ? t == null || t.appWidget.classList.remove("toggle--visible") : t == null || t.appWidget.classList.add("toggle--visible"), n = a;
          });
          break;
        case "onScrollUp":
          window.addEventListener("scroll", function() {
            let a = window.scrollY || document.documentElement.scrollTop;
            a > n ? t == null || t.appWidget.classList.remove("toggle--visible") : t == null || t.appWidget.classList.add("toggle--visible"), n = a;
          });
          break;
      }
    };
    setTimeout(() => {
      u();
    }, m);
  }
}
const S = {
  os: q,
  isMobile: C,
  isCanvas: E
};
w && function(o) {
  o.QrWidget = D, o.deviceData = S;
}(window);
export {
  D as QrWidget,
  S as deviceData
};
